import request from '@/utils/request'

/**
 * 客户公海
 */
export class ContactSeaApi {

  // 公海客户列表
  static getContactSeaList (params) {
    return request({
      url: '/contactSea/contactIndex',
      method: 'get',
      params: params
    })
  }
  
  // 把客户放入公海
  static putSeaApi (params) {
    return request({
      url: '/contactSea/putSea',
      method: 'post',
      data: params
    })
  }
  
  // 批量把客户放入公海
  static batchPutSeaApi (params) {
    return request({
      url: '/contactSea/batchPutSea',
      method: 'post',
      data: params
    })
  }
  // 从公海领取客户
  static receiveApi (params) {
    return request({
      url: '/contactSea/receiveFromSea',
      method: 'post',
      data: params
    })
  }
  
  
  // 批量从公海领取客户
  static receiveBatchApi (params) {
    return request({
      url: '/contactSea/receiveBatchFromSea',
      method: 'post',
      data: params
    })
  }
  
  // 公海类别列表
  static getSeaCategoryList (params) {
    return request({
      url: '/contactSea/seaCategoryList1',
      method: 'get',
      params: params
    })
  }  
  
}


